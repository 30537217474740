<template>
  <v-container>
    <v-card max-width="547" class="mx-auto pt-6 px-3 px-md-11 pb-8">
      <div class="text-center">
        <v-icon size="94" class="mb-2">$pendingPhase</v-icon>
      </div>

      <h1 class="font-medium-20 gray12--text text-center mb-1">
        {{ $t("panel.businessesPage.createPosGatewayReport.wellDone") }}
      </h1>

      <p class="gray10--text text-center mb-8 px-2 text-pre-line">
        {{
          $t("panel.businessesPage.createPosGatewayReport.posGatewayCreated")
        }}
      </p>

      <div class="pb-3 mb-8">
        <div class="font-medium-14 gray12--text mb-6">
          {{ $t("panel.businessesPage.createPosGatewayReport.installApp") }}
        </div>

        <div class="d-flex justify-space-between mb-6">
          <a
            :href="googlePlayAddress"
            target="_blank"
            class="text-decoration-none"
          >
            <v-icon size="24" color="transparent" class="me-2"
              >$googlePlay</v-icon
            >

            <span class="gray11--text">
              {{ $t("panel.businessesPage.createPosGatewayReport.googlePlay") }}
            </span>
          </a>
          <v-tooltip top color="white">
            <template v-slot:activator="{ on, attrs }">
              <a :href="googlePlayAddress" target="_blank">
                <v-icon size="24" color="transparent" v-bind="attrs" v-on="on"
                  >$qrCode
                </v-icon>
              </a>
            </template>
            <!--                        <v-icon size="233" color="transparent"> $googlePlayQRCode </v-icon>-->
            <v-img
              :src="require('@/assets/image/googlePlayqrcode.svg')"
              class="d-block"
              contain
              height="150px"
              width="150px"
            />
          </v-tooltip>
        </div>

        <v-divider></v-divider>

        <div class="d-flex justify-space-between mt-6">
          <a class="text-decoration-none" :href="newApkAddress">
            <v-icon size="24" class="me-2">$pluginSquare</v-icon>

            <span class="gray11--text">
              {{
                $t("panel.businessesPage.createPosGatewayReport.downloadApk")
              }}
            </span>
          </a>

          <a :href="newApkAddress">
            <v-icon size="24">$downloadArrow</v-icon>
          </a>
        </div>
      </div>

      <v-btn
        color="gray2"
        block
        link
        depressed
        height="50"
        @click="goToBusinesses"
      >
        <span class="font-semiBold-14 gray12--text">
          {{ $t("panel.businessesPage.createPosGatewayReport.goToBusinesses") }}
        </span>
      </v-btn>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: "create-web-gateway-report",
  components: {},
  data() {
    return {
      oldApkAddress: `${process.env.VUE_APP_PUBLIC_BASE_URL}/apk/riverpay.apk`,
      newApkAddress:
        "https://cdn.riverpay.io/riverpay-fat-prod-v1.0.3%2B103.apk",
      googlePlayAddress:
        "https://play.google.com/store/apps/details?id=com.blockchaincommodities.riverpay",
    };
  },
  methods: {
    goToBusinesses() {
      this.$router.replace("/panel/businesses");
    },
  },
};
</script>

<style scoped></style>
